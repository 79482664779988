@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Metamorphous&display=swap');

img{
    filter: drop-shadow(0 0 0.2rem black);
}
video {
    filter: brightness(65%);
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    background: black;
}

.mainpage-container{
    text-shadow: 2px 2px 2px #000000, 0 0 2em #000000, 0 0 2em #000000;
}
.mainpage-container-mobile{
    background-image: url("https://i.pinimg.com/originals/6d/b8/79/6db8798dd19bdb6cd5397a7e2a5e9909.jpg");
    background-position: center;
    background-size: cover;
    height: 100%;
}
.hero-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin:auto;
}

.hero-container > img {
    width: 100%;
    max-width: 1000px;
    filter: drop-shadow(0 0 1rem black);

}

.animated-text > h2 {
    color: #7d92ff;
    width: 100%;
    max-width: 1000px;
    text-align: center;
    margin-top: -50px;
    font-size: clamp(22px, 2vw + 0.3rem, 35px);
    font-family: 'Courgette', cursive;
}

.animated-text > h3 {
    color: #7d92ff;
    width: 100%;
    padding-top: 10px;
    font-size: clamp(16px, 0.8vw + 0.3rem, 24px);
    font-family: 'Courgette', cursive;
    margin-bottom: 50px;
}
.hero-btns {
    margin-top: 32px;
    margin-bottom: 40vh;
}

.hero-btns .btn {
    margin: 6px;
}
.mainpage-links {
    color: #e3d8d8;
    display: inherit;
    text-decoration: none;
    font-size: 20px;
    padding: 0.25rem 0.5rem;
    font-family: 'Metamorphous', 'PT Sans', sans-serif;
}


@media screen and (max-width: 1050px) {
    .mainpage-links {
        padding: 0.5rem 1rem;
    }
}

@media screen and (max-width: 768px) {
    .animated-text > h2 {
        width: 95%;
        margin-top: -20px;
        text-align: center;
    }
    .animated-text > h3 {
        text-align: center;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}