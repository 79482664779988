.info-page-container {
    height: 100%;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    overflow: hidden;
}

.info-box-container {
    width: 90%;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 50px;
}

.info-paragraph {
    background-image: linear-gradient(-44deg, #deeeda, rgba(214, 221, 232, 0.85));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;

    text-align: center;
    font-size: 18px;
    filter: drop-shadow(5px 3px 1px #000000);
}

.info-paragraph > p {

}

.info-column {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.info-image {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.71);
}

.info-logo {
    width: 100%;
    filter: drop-shadow(15px 5px 2px #000000);
    opacity: 70%;

}

@media screen and (min-width: 1100px) {
    .info-row {
        display: flex;

    justify-content: center;
    }

    .info-paragraph {
        font-size: 22px;
    }

    .info-box-container {
        width: 65%;

    }

}