@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@1,500&family=Itim&family=Metamorphous&family=Roboto&display=swap');

.cp-page-container {
    height: 100%;
    min-height: 100vh;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, rgba(36, 37, 42, 0.92) 100%);
    overflow: hidden;
}

.cp-box-container {
    width: 70%;
    margin: auto;
    padding-top: 10vh;
    padding-bottom: 10vh;
    text-align: justify;
}

.cp-box-container > h1 {
    text-align: center;
    color: #2661f3;
    filter: drop-shadow(3px 3px 3px #000000);
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: clamp(30px, 2vw + 0.3rem, 60px);
}

.cp-paragraph {
    background-image: linear-gradient(-44deg, #7edcff, rgba(255, 242, 156, 0.85));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    font-size: clamp(13px, 0.8vw + 0.3rem, 24px);
    text-align: justify;
    filter: drop-shadow(1px 1px 1px #6c3636);
    margin-bottom: 25px;
}

.cp-header > h1 {
    color: #2661f3;
    filter: drop-shadow(2px 2px 2px #000000);
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: clamp(20px, 1.3vw + 0.5rem, 50px);
}

.cp-columns {
    margin-top: 20px;
    column-count: 3;
    column-width: 250px;
    text-align: center;
    column-rule: 1px dotted #b2b2b6;
    column-gap: 3em;
}

.cp-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cp-header > img {
    filter: invert();
}

.cp-div > li {
    font-size: clamp(13px, 0.8vw + 0.2rem, 21px);
    font-family: 'Roboto', sans-serif;
    text-align: left;
    margin-left: 30px;
    color: rgb(204, 192, 192);
    filter: drop-shadow(2px 2px 2px #000000);
    margin-bottom: 10px;
}

.cp-div {
    margin-bottom: 40px;
    break-inside: avoid;
}

@media (hover: none) {
    .cp-box-container {
        width: 90%
    }
}