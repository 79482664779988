:root {
    --primary: rgba(106, 130, 248, 0.83);
}

.btn {
    padding: 5px 18px;
    border-radius: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: 'Metamorphous', 'PT Sans', sans-serif;
    text-shadow: 1px 3px 3px black;
        box-shadow: 0 0 5px 2px rgba(27, 216, 241, 0.28);


}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--filled {
    background-color: transparent;
    color: #6e6e6e;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 16px;
}

.btn--companion {
    margin-top: 10px;
    padding: 8px 20px;
    font-size: 16px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.btn--small {
    padding: 6px 15px;
    font-size: 14px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 18px;

}
.btn--mainpage{
    background-color: #000000;
}


.btn--large-nohover {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--large:hover,
.btn--small:hover,
.btn--medium:hover,
.btn--companion:hover,
.btn--mainpage--large:hover,
.btn--mainpage:hover{
    transition: all 0.3s ease-out;
    background: rgba(27, 216, 241, 0.28);
    color: #fdfdfd;

    transition: 250ms;
}

@media screen and (max-width: 1000px) {
    .btn--mainpage{
    font-size: 10px;
    padding: 0px 0px;
    ;

}
}