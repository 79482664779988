@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Metamorphous&display=swap');

.card {
    width: 30rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: rgba(68, 68, 68, 0.85);
    border: 7px solid #13130d;
    box-shadow: black;
    border-radius: 20px;
    padding: 0px 26px 2rem 26px;
    margin-right: 10px;
    margin-bottom: 50px;
    text-shadow: 1px 3px 3px black;
    position: relative;
    color: #d5d4d4;
}

.card-upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 10px;
}

.card-upper > img{
    width: clamp(75px, 2vw + 0.3rem, 100px);
}

.card-upper > div {
    font-size: clamp(22px, 2vw + 0.3rem, 35px);
    color: #7d92ff;
    text-shadow: 1px 3px 3px black;
    font-family: 'Metamorphous', 'PT Sans', sans-serif;
    margin-bottom:15px;
}

.card-down {
    margin-top: auto;
}

.text-justified {
    text-align: justify;
}

.card-down > text {
    text-align: justify;
    color: #7d92ff;
    font-size: clamp(19px, 2vw + 0.3rem, 30px);
    font-family: 'Metamorphous', 'PT Sans', sans-serif;
}

.card-upper > span {
    text-align: justify;
}

.card-down > span {
    text-align: justify;
}

.card-back {
    margin-top: 50px;
}

.navbar-margin {
    padding-top: 80px;
}


@media only screen and (max-width: 1500px) {
    .card-down > text {
        padding-top: 50px;
    }

    .card {
        width: 60%;
        margin: auto;
        margin-bottom: 40px;
        height: auto;
    }

    .card-upper {
        height: auto;
    }

}

@media screen and (max-width: 1000px) {
    .card {
        width: 85%;
    }
}


