* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

.home,
.placeholder {
    display: flex;
    background-color: white;
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    background-color: #151b2a;
    color: #8c01c4;
    font-size: 50px;
}
.black-bg{
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
}
.signup-container {
    margin: 100px auto;
    width: 80%;
    position: relative;
    height: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.login-btn {
    width: 75%;
    height: 40px;
    margin-top: 15px;
    margin-left: 42px;
    margin-bottom: 3px;
    border-radius: 2px;
    background: #09bd97;
    outline: none;
    border: none;
    color: #fff;
    font-size: 1rem;
}

.login-btn:hover {
    cursor: pointer;
    color: #09bd97;
    background: rgb(229, 255, 250);
    transition: all 0.4s ease-out;
}

.login-inputs {
    margin-bottom: 0.8rem;
    margin-left: 42px;
    width: 75%;
    position: relative;
}

.login-headline {
    size: 70px;
    margin-left: 80px;
    color: white;
    text-anchor: middle;
    position: center;
}


