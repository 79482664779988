@import url('https://fonts.googleapis.com/css2?family=Itim&family=Metamorphous&display=swap');


.pass > text {
    font-family: 'Itim', cursive;
    font-size: clamp(16px, 0.8vw + 0.3rem, 24px);
}

.pass-red > text {
    font-family: 'Itim', cursive;
    font-size: 20px;
    color: #cc3f3f;
}
