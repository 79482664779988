@import url('https://fonts.googleapis.com/css2?family=Itim&family=Metamorphous&display=swap');

.about-us-container {
    display: flex;
    flex-direction: column;
    position: relative;
    column-count: 2;
    margin: auto;
    width: clamp(60%, 60% - 2rem, 95%);
    top: 150px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
    outline-width: 10px;
}

.about-us-container > div {
    width: 95%;
    margin: 20px auto;
}
@media (hover: none) {
    .about-us-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    top:0px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
    outline-width: 10px;
}
    .about-us-container > div {
    width: 100%;
    margin: 0px auto;
}

}

