
.calculator{
    align-content: center;
}
.calc-label {
    display: inline-block;
    margin-right: 6px;
    color: rgba(187, 187, 187, 0.9);
    padding-left: 20px;
    font-size: clamp(13px, 0.8vw + 0.3rem, 24px);
    font-family: 'Itim', cursive;
}
.select{
    background-color: rgba(187, 187, 187, 0.7);
    border-color: rgba(255, 255, 255, 0);
    outline-color: blue;
    accent-color: #3a4f9d;
    margin-bottom: 5px;
}