@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Itim&family=Metamorphous&display=swap');

.practic-page-container {
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.practic-box-container {
    margin: auto;
    width: 70%;
    padding-top: 12vh;
    padding-bottom: 10vh;
    margin-bottom: 0rem;
}

.practic-columns {
    column-count: 3;
    column-width: 300px;
    text-align: center;
    column-rule: 1px dotted #ccc;
    column-gap: 2em;
    box-shadow: inset 0 0 0 1000px rgba(21, 19, 19, 0.9);
}

.intro-header {
    column-span: all;
    color: #bbbbbb;
    font-family: 'Metamorphous', 'PT Sans', sans-serif;
    font-size: clamp(35px, 2vw + 0.3rem, 60px);
    padding-top: 25px;
    padding-bottom: 10px;
}


.practic-columns > h2 {
    font-family: 'Metamorphous', 'PT Sans', sans-serif;
    text-shadow: 1px 3px 3px black;
    box-shadow: inset 0 0 0 1000px rgba(121, 121, 121, 0.43);
    font-size: clamp(22px, 2vw + 0.3rem, 35px);
    margin-bottom: 30px;
    color: #3862c4;
}
.practic-columns > li {
    font-family: 'Metamorphous', 'PT Sans', sans-serif;
    text-align: left;
    margin-left: 30px;
    color: #27c3da;
    text-shadow: 1px 1px 1px #000000;
    break-after: avoid;

}

.practic-columns > p {
    text-align: justify;
    color: rgba(187, 187, 187, 0.9);
    padding-left: 20px;
    font-size: clamp(16px, 0.8vw + 0.3rem, 24px);
    font-family: 'Itim', cursive;
    padding-right: 20px;
    padding-bottom: 20px;

}
@media (hover: none) {
    .practic-box-container {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0rem;
    }
}