@import url('https://fonts.googleapis.com/css2?family=Itim&family=Metamorphous&display=swap');

.AuthorContainer {
    display: flex;
    flex-direction: row;
}


.background-gradient {
    background: linear-gradient(-30deg, rgb(45, 45, 45), #000000);
    border: 10px solid;
}

.background-gradient2 {
    background: linear-gradient(-150deg, #000000, rgb(45, 45, 45));
    border: 10px solid;
}

.AuthorText {
    width: 100%;
}

.ImageRight {
    transform: scaleX(-1);
}

.AuthorText > h1 {
    margin-top: 20px;
    font-size: clamp(25px, 2vw + 0.3rem, 55px);
    text-align: center;
    color: #27c3da;
    font-family: 'Metamorphous', 'PT Sans', sans-serif;
}

.AuthorText > p {
    width: 90%;
    text-align: justify;
    color: #bbbbbb;
    font-size: clamp(16px, 0.8vw + 0.3rem, 24px);
    margin: 20px auto auto;
    font-family: 'Itim', cursive;
}

@media (hover: hover) {
    /* targets devices that can hover */
}
@media (hover: none) {
    /* targets mobile phones with no hover */
    .ImageLeft > img {
        width: 20vw;
        min-width: 150px;
        max-width: 400px;
    }
    .ImageLeft > div {
        width: 20vw;
        min-width: 150px;
        max-width: 400px;
    }
    .ImageRight > img {
        width: 20vw;
        min-width: 150px;
        max-width: 400px;
    }
    .ImageLeft > img {
        width: 20vw;
        min-width: 150px;
        max-width: 400px;
    }
    .ImagePhone > img {
        width: 40vw;
        min-width: 150px;
        max-width: 400px;
    }
    .ImagePhone > div{
        padding: 50px;
    }
    .AuthorText > p {
    margin-bottom: 20px;
}
    .background-gradient {
    border: 0px solid;
}

    @media screen and (max-width: 1000px) {
        .AuthorContainer {
            display: grid;
            justify-items: center;
        }
    }
}
