@import url('https://fonts.googleapis.com/css2?family=Itim&family=Metamorphous&display=swap');

.pass-cursive {
    font-style: italic;
}

.CompanionshipHeader {
    margin-bottom: 10px;
    margin-top: 40px;
}

.CompanionshipText {
    margin-bottom: 10px;
    max-width: 50%;
    margin: auto;
}

.cards__container {
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 0px;
}

@media only screen and (min-width: 1500px) {
    .cards__container {
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
    }
}
