.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-logo {
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    justify-content: left;
    width: 200px;
    margin-left: 50px;
    transition: 2s ease ;
}

.navbar-logo img:hover{
-webkit-transform: rotateZ(-360deg);
-ms-transform: rotateZ(-360deg);
transform: rotateZ(-360deg);
transition: 2s ease;
}

.navbar-logo-centered {
    justify-self: start;
    font-size: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-item {
    height: 80px;
}

.nav-links {
    color: #e3d8d8;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: 'Metamorphous', 'PT Sans', sans-serif;
}

.nav-links:hover {
    color: #7d92ff;
    border-bottom: 4px solid #7d92ff;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
    font-family: 'Metamorphous', 'PT Sans', sans-serif;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .navbar-logo {
        margin-left: 30px;
    }

    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: auto;
        position: absolute;
        top:60%;
        visibility:hidden;
        left: -100%;
        opacity: 0.1%;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #1c1818;
        left: 0%;
        top:100%;
        visibility: visible;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #bbbbbb;
        color: #242424;
        border-radius: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 1vw;
        transform: translate(-100%, 60%);
        transition-timing-function: linear;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.2rem;
        background-color: transparent;
        color: #fff;
        border-radius: 10px;
        padding: 14px 20px;
        border: 1px solid #1969ff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }
}






