.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  justify-content: center;
  max-width: 80vw;
  margin: 0 auto;
}
.single{
  height: 300px;
  width: 300px;
}
.single >img{
  width: inherit; /* width of container */
  height: inherit; /* height of container */
  object-fit: cover;
  object-position: 20% 10%;
  width: fit-content;
  cursor: pointer;
}

.galleryWrap .single img {
  max-width: 100%;
  filter: grayscale(50%);

  transition-duration: 0.35s;

}

.galleryWrap .single img:hover {
  transform: scale(1.1);
  filter: grayscale(0%);

}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
  position: fixed;
  height: 2.5vh;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
}

.btnNext:hover, .btnPrev:hover, .btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 1s;
  text-align: center;
  color: #aba7a7;

}

.fullScreenImage img {
  max-width: 90vw;
  max-height: 90vh;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}