
.footer-container {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    position: relative;
}

.footer-text-container {
    display: flex;
    width: 60%;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 15px;
    color: #fff;
}

.footer-text-container > p {
    font-family: 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-header {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-text {
    margin-bottom: 24px;
    font-size: 15px;
}

.footer-text2 {
    font-size: 12px;
}

.footer-text3 {
    font-size: 12px;
    position: relative;
    min-width: 50px;
}

.footer-links {
    width: 100%;
    display: flex;
}


/* Social Icons */
.social-icon-link {
    color: #fff;
    font-size: 24px;
}

.social-media-wrap {
    width: 30%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    margin: auto;
    align-items: center;
}

.social-icons {
    display: flex;
    align-items: center;

}


@media screen and (max-width: 820px) {
    .social-media-wrap {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 10px;
    }
}